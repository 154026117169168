import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TextField} from "@material-ui/core";
import Pink from "@material-ui/core/colors/pink";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({
    divider: {
        marginBottom: theme.spacing(1.5)
    },
    input: {
        display: 'flex',
        flexBasis: 250,
        flexGrow: 1,
        minHeight: 70
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    };


    render() {
        const {open, handleClick, handleUpdate, classes, handleChange, user} = this.props


        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => handleClick()}
                    fullWidth
                    maxWidth={'sm'}

                >
                    <DialogTitle id="alert-dialog-slide-title">{"Edit User"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            name={"first_name"}
                            label="First name"
                            className={classes.input}
                            fullWidth
                            variant="outlined"
                            value={user ? user.first_name : ''}
                            onChange={handleChange}
                        />
                        <TextField
                            name={"last_name"}
                            label="Last name"
                            className={classes.input}
                            fullWidth
                            variant="outlined"
                            value={user ? user.last_name : ''}
                            onChange={handleChange}

                        />
                        <TextField
                            name={"email"}
                            label="Email"
                            className={classes.input}
                            fullWidth
                            variant="outlined"
                            value={user ? user.email : ''}
                            onChange={handleChange}

                        />

                        <TextField
                            name={"password"}
                            label="New Password"
                            className={classes.input}
                            fullWidth
                            type={'password'}
                            variant="outlined"
                            onChange={handleChange}

                        />
                        <TextField
                            name={"confirm_password"}
                            label="Confirm Password"
                            className={classes.input}
                            fullWidth
                            type={'password'}
                            variant="outlined"
                            onChange={handleChange}

                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClick()} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => handleUpdate(user)} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

export default withStyles(styles)(EditUser)