import React, { useState, useEffect } from "react";
import TimeAgo from "react-timeago";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { deleteOpportunity, getOpportunity, updateOpportunity } from "../../../Axios";
import { connect } from "react-redux";
import styles from "./OpportunityStyles";
import { useParams, useHistory } from "react-router-dom";
import fields from "../FormFields";
import Loader from "../../../components/Loader";
import Comments from "../../../components/comments/Comments";
import IOpportunity from "../../../interfaces/IOpportunity";
import { capitalize, getDateAndTime, getProjectNumber } from "../../../util/functions";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  withStyles
} from "@material-ui/core";
import { projectDatabaseUrl, api } from "../../../util/constants";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const Opportunity = props => {
  const [opportunity, setOpportunity] = useState<IOpportunity>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(
    () =>
      getOpportunity(id, opportunity => {
        setOpportunity(opportunity);
        setLoading(false);
      }),
    [id]
  );

  const handleArchive = () => {
    setAnchorEl(null);
    setLoading(true);
    updateOpportunity({ ...opportunity, archived: !opportunity.archived })
      .then(opportunity => {
        setOpportunity(opportunity);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id = null) => {
    if (id) {
      deleteOpportunity(id, status => {
        if (status === 200) {
          history.push("/");
        }
      });
    } else {
      setDeleteModal(false);
    }
  };

  const handleUpdate = opportunity => {
    setOpportunity(opportunity);
    setAnchorEl(null);
    setLoading(false);
    setDeleteModal(false);
  };

  const { classes, user } = props;
  const open = Boolean(anchorEl);

  return (
    <Loader loading={loading}>
      {opportunity ? (
        <div className={classes.root}>
          <Paper className={classes.paper} elevation={10}>
            <div>
              <div className={classes.headerContainer}>
                <div className={classes.alignLeft}>
                  <Chip
                    className={classes.chip}
                    color={!opportunity.archived ? "primary" : "default"}
                    label={!opportunity!.archived ? "Active" : "Archived"}
                  />
                  {!!opportunity?.project?.id && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={projectDatabaseUrl + "/projects/" + opportunity.project.id}
                    >
                      <Chip
                        className={classes.chipLink}
                        size="medium"
                        label={getProjectNumber(
                          opportunity.project.project_number.toString(),
                          opportunity.project.year.toString(),
                          opportunity.project.owner
                        )}
                        color={"primary"}
                      />
                    </a>
                  )}
                </div>
                <div className={classes.alignRight}>
                  <TimeStamp name={opportunity.author} date={opportunity.created_date} />

                  <IconButton
                    className={classes.floatRight}
                    aria-label="More"
                    aria-owns={open ? "long-Navigation" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "fit-content",
                        right: 30,
                        left: 0
                      }
                    }}
                  >
                    <MenuItem onClick={() => history.push("/opportunities/edit/" + opportunity._id)}>Edit</MenuItem>

                    <MenuItem
                      onClick={() => {
                        setDeleteModal(true);
                        setAnchorEl(null);
                      }}
                    >
                      Delete
                    </MenuItem>

                    {user.type === 1 && (
                      <MenuItem onClick={() => handleArchive()}>
                        {opportunity.archived ? "Unarchive" : "Archive"}
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </div>
              {/* End of HeaderContainer*/}
            </div>
            <div className={classes.row}>
              <Typography variant="h5" className={classes.title} component="h3">
                {opportunity.title}
              </Typography>

              <Divider />
            </div>
            <div className={classes.infoRow}>
              {fields
                .filter(field => field.category === "details")
                .map(field => (
                  <div className={classes.item} key={field.name}>
                    <Typography variant={"h6"} component="div">
                      {capitalize(field.name.split("_").join(" "))}:
                    </Typography>
                    <Typography variant={"body1"}>
                      {field.type === "number" && opportunity[field.name]
                        ? opportunity[field.name].toLocaleString("en-GB")
                        : field.type === "date" && opportunity[field.name]
                        ? getDateAndTime(opportunity[field.name])
                        : opportunity[field.name]}
                    </Typography>
                  </div>
                ))}
            </div>
            <Divider />

            {fields
              .filter(field => field.category === "textarea")
              .map(field => (
                <div className={classes.row} key={field.name}>
                  <Typography variant={"h6"}>{capitalize(field.name.split("_").join(" "))}:</Typography>
                  <Typography
                    variant={"body1"}
                    dangerouslySetInnerHTML={{ __html: opportunity[field.name] }}
                  ></Typography>
                </div>
              ))}

            {!!opportunity.files.length && (
              <div className={classes.row}>
                <Typography variant={"h6"}>Related files:</Typography>
                {opportunity.files.map((file, index) => (
                  <div>
                    <a
                      className={classes.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={api.url + "/uploads/" + opportunity._id + "?load=" + encodeURIComponent(file)}
                      download
                    >
                      {file}
                    </a>
                  </div>
                ))}
              </div>
            )}

            <Comments
              handleUpdate={handleUpdate}
              comments={opportunity.comments}
              id={opportunity._id}
              type={"opportunities"}
            />
          </Paper>
        </div>
      ) : null}

      <DeleteDialogue open={deleteModal} opportunity={opportunity} handleClick={handleDelete} />
    </Loader>
  );
};

const TimeStamp = ({ date, name }) => {
  return (
    <Typography variant={"body2"}>
      Added by {name}{" "}
      <TimeAgo
        date={date}
        formatter={(value, unit, suffix) => {
          if (unit === "second") {
            return "just now";
          } else {
            return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
          }
        }}
      />
    </Typography>
  );
};

const DeleteDialogue = ({ open, opportunity, handleClick }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deleting {opportunity.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you wish to delete this post?
          <br />
          <br />
          Deleting a post is permanent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClick(opportunity._id)} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(Opportunity));
