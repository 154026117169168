import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography/index";
import withStyles from "@material-ui/core/styles/withStyles";
import { getNews, getOpportunities } from "../../Axios";
import Divider from "@material-ui/core/Divider";
import styles from "./HomeStyles";
import Loader from "../../components/Loader";
import OpportunityCard from "../../components/opportunityCard/OpportunityCard";
import { commentCounter } from "../../util/functions";
import NewsCard from "../../components/newsCard/NewsCard";
import FAButton from "../../components/fabButton/FAButton";
import IOpportunity from "../../interfaces/IOpportunity";
import { Grid } from "@material-ui/core";

const Home = props => {
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [news, setNews] = useState([]);
  const [opportunitiesLoading, setOpportunitiesLoading] = useState(true);
  const [newsLoading, setNewsLoading] = useState(true);

  useEffect(() => {
    getOpportunities(opportunities => {
      setOpportunities(opportunities);
      setOpportunitiesLoading(false);
    });
    getNews(news => {
      setNews(news);
      setNewsLoading(false);
    });
  }, []);

  const { classes } = props;

  return (
    <Loader loading={opportunitiesLoading || newsLoading}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item md={4}>
          <div className={classes.header}>
            <Typography variant="h6" className={[classes.title, classes.inProgress].join(" ")}>
              In Progress
            </Typography>
            <Divider />
          </div>

          {opportunities.length ? (
            <div>
              {opportunities
                .filter(opportunity => !opportunity.archived && opportunity.status === "In Progress")
                .filter(opportunity => !["Submitted"].includes(opportunity.stage))
                .sort(compare)
                .map((opportunity, index) => (
                  <div key={index} className="buffer">
                    <OpportunityCard
                      id={opportunity._id}
                      color="blue"
                      opportunity={opportunity}
                      title={opportunity.title}
                      created_date={opportunity.created_date}
                      comments={commentCounter(opportunity.comments)}
                      status={opportunity.stage}
                      deadline={opportunity.application_deadline}
                    />
                  </div>
                ))}
              <div>
                <Typography variant="h6" className={[classes.title, classes.inProgress].join(" ")}>
                  Submitted
                </Typography>
                <Divider />
              </div>
              {opportunities
                .filter(opportunity => !opportunity.archived && opportunity.status === "In Progress")
                .filter(opportunity => ["Submitted"].includes(opportunity.stage))
                .map((opportunity, index) => (
                  <div key={index} className="buffer">
                    <OpportunityCard
                      id={opportunity._id}
                      color="blue"
                      opportunity={opportunity}
                      title={opportunity.title}
                      created_date={opportunity.created_date}
                      comments={commentCounter(opportunity.comments)}
                      status={opportunity.stage}
                    />
                  </div>
                ))}
              <div>
                <Typography variant="h6" className={[classes.title, classes.inProgress].join(" ")}>
                  Won
                </Typography>
                <Divider />
              </div>
              {opportunities
                .filter(opportunity => !opportunity.archived && ["Won"].includes(opportunity.status))
                .map((opportunity, index) => (
                  <div key={index} className="buffer">
                    <OpportunityCard
                      id={opportunity._id}
                      color="blue"
                      opportunity={opportunity}
                      title={opportunity.title}
                      created_date={opportunity.created_date}
                      comments={commentCounter(opportunity.comments)}
                      status={opportunity.status}
                    />
                  </div>
                ))}
              <div>
                <Typography variant="h6" className={[classes.title, classes.inProgress].join(" ")}>
                  Dropped &amp; Lost
                </Typography>
                <Divider />
              </div>
              {opportunities
                .filter(opportunity => !opportunity.archived && ["Dropped", "Lost"].includes(opportunity.status))
                .map((opportunity, index) => (
                  <div key={index} className="buffer">
                    <OpportunityCard
                      id={opportunity._id}
                      color="blue"
                      opportunity={opportunity}
                      title={opportunity.title}
                      created_date={opportunity.created_date}
                      comments={commentCounter(opportunity.comments)}
                      status={opportunity.status}
                    />
                  </div>
                ))}
            </div>
          ) : (
            "No Opportunities Found"
          )}
        </Grid>

        <Grid item md={4}>
          <div className={classes.header}>
            <Typography variant="h6" className={[classes.title, classes.newOpportunities].join(" ")}>
              New Opportunities
            </Typography>
            <Divider />
          </div>

          {opportunities.length ? (
            <div>
              {opportunities
                .filter(opportunity => !opportunity.archived && opportunity.status === "New Opportunity")
                .map((opportunity, index) => (
                  <div key={index} className="buffer">
                    <OpportunityCard
                      id={opportunity._id}
                      opportunity={opportunity}
                      title={opportunity.title}
                      created_date={opportunity.created_date}
                      comments={commentCounter(opportunity.comments)}
                      inProgress={opportunity.status}
                    />
                  </div>
                ))}
            </div>
          ) : (
            "No Opportunities Found"
          )}
        </Grid>

        <Grid item md={4}>
          <div className={classes.header}>
            <Typography variant="h6" className={[classes.title, classes.news].join(" ")}>
              News
            </Typography>
            <Divider />
          </div>
          {news.length ? (
            <div>
              {news
                .filter(news => !news.archived)
                .map((news, index) => (
                  <div key={index} className="buffer">
                    <NewsCard id={news._id} news={news} comments={commentCounter(news.comments)} />
                  </div>
                ))}
            </div>
          ) : (
            "No News Found"
          )}
        </Grid>
        <FAButton />
      </Grid>
    </Loader>
  );
};

export default withStyles(styles)(Home);

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const genreA = new Date(a.application_deadline);
  const genreB = new Date(b.application_deadline);

  let comparison = 0;
  if (genreA > genreB) {
    comparison = 1;
  } else if (genreA < genreB) {
    comparison = -1;
  }
  return comparison;
}
