import React, { useState, useEffect, FunctionComponent, useCallback } from "react";
import { Button, Divider, Typography, withStyles, Paper, TextField } from "@material-ui/core";
import styles from "./OpportunityFormStyles";
import PropTypes from "prop-types";
import { createOpportunity, getFilters, getOpportunity, updateOpportunity } from "../../Axios";
import fields from "./FormFields";
import BackIcon from "@material-ui/icons/ArrowBack";
import { Link, useParams, useHistory } from "react-router-dom";
import SelectComponent from "../../components/input/Select";
import Editor from "../../components/Editor";
import NumberComponent from "../../components/input/Number";
import DateComponent from "../../components/input/Date";
import TextComponent from "../../components/input/Text";
import Loader from "../../components/Loader";
import IOpportunity from "../../interfaces/IOpportunity";
import FileUpload from "../../components/fileUpload/FileUpload";

type Props = {
  edit?: boolean;
  classes: any;
};

const OpportunityForm: FunctionComponent<Props> = props => {
  const [opportunity, setOpportunity] = useState<IOpportunity>({});
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id } = useParams();
  const { classes, edit } = props;

  useEffect(() => {
    getFilters(filters => setFilters(filters));
    edit
      ? getOpportunity(id, opportunity => {
          setOpportunity(opportunity);
          setLoading(false);
        })
      : setLoading(false);
  }, []);

  const handleChange = useCallback(
    (property: string, value: any) => {
      setOpportunity({ ...opportunity, [property]: value });
    },
    [opportunity]
  );

  const handleSave = () => {
    setLoading(true);
    const files = opportunity.filepond?.map(fileItem => fileItem.file.name);
    createOpportunity({
      ...opportunity,
      files: files
    })
      .then((opportunity: any) => history.push("/opportunities/" + opportunity._id))
      .catch(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    const files = opportunity.filepond?.map(fileItem => fileItem.file.name);
    updateOpportunity({
      ...opportunity,
      files: files
    })
      .then((opportunity: any) => history.push("/opportunities/" + opportunity._id))
      .catch(() => setLoading(false));
  };

  const ComponentSwitch = field => {
    switch (field.type) {
      case "select":
        return (
          <SelectComponent
            key={field.name}
            field={field.name}
            filters={filters}
            handleChange={handleChange}
            value={opportunity?.[field.name]}
            isClearable={field.clearable}
            isMulti={field.multi}
          />
        );
      case "editor":
        return (
          <Editor key={field.name} field={field.name} value={opportunity?.[field.name]} handleChange={handleChange} />
        );
      case "number":
        return (
          <NumberComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            format={field.format && field.format}
            value={opportunity?.[field.name]}
          />
        );
      case "date":
        return (
          <DateComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            value={opportunity?.[field.name]}
            includeTime={field.includeTime}
          />
        );
      case "text":
        return (
          <TextComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            value={opportunity?.[field.name]}
          />
        );
    }
  };

  return (
    <Loader loading={loading}>
      <Paper className={classes.paper} elevation={10}>
        <div className={"container"}>
          <div>
            {edit && (
              <Link to={"/opportunities/" + opportunity._id}>
                <Button
                  startIcon={<BackIcon />}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  className={classes.margin}
                >
                  Back to Opportunity
                </Button>
              </Link>
            )}
            <div className={classes.row}>
              <Typography variant="h4" className={classes.title}>
                {edit ? "Edit" : "Create"} Opportunity
              </Typography>
            </div>
            <Divider className={classes.divider} />

            <div className={classes.row}>
              <TextField
                id="title"
                label="Title*"
                className={classes.input}
                variant="outlined"
                value={opportunity.title ?? ""}
                onChange={e => handleChange(e.target.id, e.target.value)}
                fullWidth
              />
            </div>

            <div className={classes.row}>
              {fields.filter(field => field.category === "details").map(field => ComponentSwitch(field))}
            </div>
            <div className={classes.row}>
              {fields.filter(field => field.category === "textarea").map(field => ComponentSwitch(field))}
            </div>

            <div style={{ margin: "24px 0px 24px 0px" }}>
              <FileUpload id={opportunity._id} data={opportunity.files} handleUpdate={handleChange} />
            </div>
          </div>
          <div style={{ justifyContent: "center" }}>
            <Button
              fullWidth
              color={"primary"}
              variant={"contained"}
              onClick={edit ? () => handleUpdate() : () => handleSave()}
            >
              Save
            </Button>
          </div>

          <div className={classes.bottomSpace} />
        </div>
      </Paper>
    </Loader>
  );
};

OpportunityForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OpportunityForm);
